<template>
    <div class="popUpForm" v-loading="loading">
        <div class="content">
            <el-tabs v-model="activeName">
                <el-tab-pane name="form">
                    <span slot="label"><i class="iconfont iconbiaodan"></i>表单</span>
                    <contractPriceForm
                        :vue-form-params="params"
                        ref="contractPrice"
                        v-if="formType==='1'"
                    ></contractPriceForm>
                    <formPage
                        :vue-form-params="params"
                        ref="adjustPrice"
                        v-else
                    ></formPage>
                </el-tab-pane>
                <el-tab-pane label="附件资料" name="enclosure" :disabled="isDisabled">
                    <span slot="label"><i class="iconfont iconwenjianjia"></i>附件资料</span>
                    <annex
                        :form-id="formId"
                        :form-key-value="code.FormKeyValue"
                        :form-data="code"
                    ></annex>
                </el-tab-pane>
            </el-tabs>
        </div>
        <footer>
            <el-button
                type="primary"
                class="save"
                @click="save"
                :loading="saveBtnLoading"
                v-if="!isReadOnly"
            >
                保存
            </el-button>
            <el-button class="Close" @click="Close">
                关闭
            </el-button>
        </footer>
    </div>
</template>

<script>
import store from '@/store';
import formPage from './contractAdjustPriceForm';
import contractPriceForm from './contractPriceForm';
import annex from '@/components/popUpForm/annex/index';

export default {
    components: {
        formPage,
        contractPriceForm,
        annex,
    },
    props: {
        code: {
            type: [Array, Object, String],
            required: true, // 是否必传
        },
    },
    data() {
        return {
            // 加载中状态
            loading: false,
            // 默认展开tab页
            activeName: 'form',
            // 附件状态
            isDisabled: true,
            // 当前表单ID
            formId: '',
            // 表单是否只读状态,判断保存按钮是否显示
            isReadOnly: false,
            // 当前表单唯一标识
            thisFormOnly: '',
            params: {
                IsRead: false,
                formId: '',
            },
            formType: null,
            // 保存按钮状态
            saveBtnLoading: false,
        };
    },
    created() {
        if (this.code.FormUrl) {
            // 表单是否只读状态,判断保存按钮是否显示
            this.isReadOnly = JSON.parse(this.$queryString(this.code.FormUrl, 'IsView'));
            this.params.IsRead = this.isReadOnly;
            // 当前表单唯一标识赋值1
            this.thisFormOnly = this.$queryString(this.code.FormUrl, 'FormCode');
            this.formType = this.$queryString(this.code.FormUrl, 'type');
        }
        if (this.code.FormKeyValue) {
            this.isDisabled = false;
            this.formId = this.code.FormKeyValue;
            // this.gitAnnexData(this.code.FormKeyValue);
            this.params.formId = this.code.FormKeyValue;
        }
    },
    methods: {
        // 保存表单
        save() {
            this.saveBtnLoading = true;
            // const iframe = window.document.getElementById('frameId' + this.thisFormOnly);
            // iframe.contentWindow.JyFormSaveFormData(this.JTFlowAfterFormSaveSuccess);
            if (this.formType === '1') {
                this.$refs.contractPrice.save(this.JTFlowAfterFormSaveSuccess, this.errorCallBack);
            } else {
                this.$refs.adjustPrice.save(this.JTFlowAfterFormSaveSuccess, this.errorCallBack);
            }

        },
        // 保存表单成功回调
        JTFlowAfterFormSaveSuccess(PKValues) {
            this.saveBtnLoading = false;
            this.isDisabled = false;
            this.params.PKValues = PKValues;
            this.formId = PKValues;
            this.$message.success('保存成功！');
            // 提交流程刷新列表
            store.state.currentOpenList.forEach(item => {
                item.tableObj.updateTable();
            });
        },
        // 保存失败回调
        errorCallBack() {
            this.saveBtnLoading = false;
        },
        // 取消添加关闭弹出层
        Close() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus"></style>
